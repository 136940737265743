@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');



*{
  box-sizing:border-box;
  margin:0;
  padding:0;
  font-family:"Outfit", sans-serif;
}

body{
  background:#8b145a;

}

h1,
h4,
p,

a{
  color:#fff;
  text-decoration:none;
}

ul{
  list-style-type:none;
}

.btn{
  padding:12px 32px;
  font-size: 1rem;
  text-transform:uppercase;
  background: rgb(248,217,15);
  color:#7f1863;
  border:1px solid rgb(236, 6, 6);
  font-weight:600;
  cursor:pointer;
}

.btn-light{
  background:transparent;
  color:#f70707;
}

.btn:hover{
  background:rgba(255,255,255,0.2);
  color:#fff;
  transition:0.3s;

}
