.header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:1rem;
}


.nav-menu {
    display:flex;

}

.nav-menu li{
    padding:0 1rem;
}

.nav li a{
    font-size: 1.2 rem;
    font-weight: 500;
}

@media screen and(max-width:1040px){
    .nav-menu{
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width:100%;
        height:100vh;
        background:rgba(0,0,0,0.9);
        position:absolute;
        top:0;
        left:0;
        z-index:-3;
    }

    .nav-menu li{
        padding:1rem 0;


    }
    .nav-menu li a{
        font-size:2rem;
    }

    .hamburger{
        display:initial;
    }

    
    
}